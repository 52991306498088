<script setup>
import solutionsItems from "~/assets/json/solutions.json";

const solution = ref({});

onMounted(() => {
  [solution.value] = solutionsItems.solutions.filter(
    (obj) => obj.slug === "businesses"
  );
});

useSeoMeta({
  title: "Trez Labs | Digital Transformation & Product Innovation Experts",
  ogTitle: "Trez Labs | Digital Transformation & Product Innovation Experts",
  description:
    "Unleash potential with Trez Labs. Accelerate with our tailored digital strategies, expert web/mobile development, big data, and DevOps consulting.",
  ogDescription:
    "Unleash potential with Trez Labs. Accelerate with our tailored digital strategies, expert web/mobile development, big data, and DevOps consulting.",
});
</script>

<template>
  <div class="homepage">
    <LazyHomepageHero />
    <LazyLanguagesDark :isShort="true" />
    <LazyHomepageWorkClosely />
    <LazySunAnimation />
    <LazyInnovative />
    <LazyHomepageTailoredSolutions />
    <LazySectionSep />
    <LazySolutionsClientsSay :clients="solutionsItems.clients" />
    <LazyLatestArticles />
    <LazyUnleash />
  </div>
</template>
